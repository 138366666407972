<template>
    <!-- 全网营销 -->
    <div class="wholeNm">
        <!-- 内容四 -->
        <MproCtFour></MproCtFour>
        <!-- 内容五 -->
        <MproCtFive></MproCtFive>
        <!-- 内容六 -->
        <MproCtSix></MproCtSix>
        <!-- 内容七 -->
        <MproCtSeven></MproCtSeven>
    </div>
</template>

<script>
import MproCtFour from "./WnContent/MproCtFour.vue";
import MproCtFive from "./WnContent/MproCtFive.vue";
import MproCtSix from "./WnContent/MproCtSix.vue";
import MproCtSeven from "./WnContent/MproCtSeven.vue";

export default {
    name: "WholeNm",
    components: {
        MproCtFour,
        MproCtFive,
        MproCtSix,
        MproCtSeven,
    },
};
</script>

<style lang="less">
.wholeNm {
    min-width: 1423px;
    margin: 0 auto;
}
</style>
