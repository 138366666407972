<template>
    <div class="mproCtSix">
        <!-- 标题 -->
        <div class="mcsTitle wow animate__animated animate__slideInUp">威有做线下宣传品物料么？</div>
        <!-- 气球图 -->
        <div class="mcsBalloon">
            <!-- 灰气球 -->
            <div class="ballonBg">
                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
            </div>
            <!-- 彩气球 -->
            <div class="balloonOn">
                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
            </div>
            <!-- 人 -->
            <div class="mcsPeople">
                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
            </div>
            <!-- 座位 -->
            <div class="mcsSeat">
                <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                <p class="msP">
                    名片 海报 宣传单 <br />
                    宣传画册 优惠卡
                </p>
            </div>
            <!-- 二维码 -->
            <div class="mcsCodes">
                <img :src="getWebData().public_phone" alt="" class="mcsImg" />
            </div>
            <div class="mcsCodeb">
                <img :src="getWebData().wechat_visitor" alt="" class="mcbImg" />
            </div>
            <div class="mcsP">数据分析</div>
        </div>
        <!-- 漂浮的云 -->
        <div class="yun1">
            <img src="http://iv.okvu.cn/vugw/img/yun-1.png" alt="" />
            <p class="yunp">流量</p>
        </div>
        <div class="yun2">
            <img src="http://iv.okvu.cn/vugw/img/yun-1.png" alt="" />
            <p class="yunp">商机</p>
        </div>
        <div class="yun3">
            <img src="http://iv.okvu.cn/vugw/img/yun-1.png" alt="" />
            <p class="yunp">客户</p>
        </div>
        <div class="yun4">
            <img src="http://iv.okvu.cn/vugw/img/yun-2.png" alt="" />
        </div>
        <div class="yun5">
            <img src="http://iv.okvu.cn/vugw/img/yun-2.png" alt="" />
        </div>
        <div class="yun6">
            <img src="http://iv.okvu.cn/vugw/img/yun-2.png" alt="" />
        </div>
        <div class="yun7">
            <img src="http://iv.okvu.cn/vugw/img/yun-1.png" alt="" />
        </div>
        <div class="yun8">
            <img src="http://iv.okvu.cn/vugw/img/yun-1.png" alt="" />
        </div>

        <img src="http://iv.okvu.cn/vugw/img/bg-2.png" alt="" class="di" />
        <img src="http://iv.okvu.cn/vugw/img/bg-1.png" alt="" class="di" />
    </div>
</template>

<script>
export default {
    name: "MproCtSix",
    inject: ["getWebData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.mproCtSix {
    width: 100%;
    height: 800px;
    margin-top: 100px;
    position: relative;
    background-color: #eaf5fb;
    overflow: hidden;
    // 标题
    .mcsTitle {
        width: 30%;
        height: 50px;
        margin: 0 auto;
        margin-top: 30px;
        line-height: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: 900;
    }
    // 气球图
    .mcsBalloon {
        width: 600px;
        height: 700px;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
        position: relative;
        z-index: 7;
        // 动画
        transform-origin: center center;
        animation-name: the-balloon;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        .ballonBg {
            width: 550px;
            height: 500px;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 30px;
            img {
                position: absolute;
                bottom: -340px;
                left: -10px;
            }
        }
        .balloonOn {
            width: 450px;
            height: 450px;
            overflow: hidden;
            position: absolute;
            top: 75px;
            left: 80px;
            img {
                position: absolute;
                bottom: -350px;
                left: -550px;
            }
        }
        .mcsPeople {
            position: absolute;
            bottom: 170px;
            left: 250px;
            width: 170px;
            height: 100px;
            overflow: hidden;
            img {
                position: absolute;
                top: -300px;
                right: -10px;
            }
        }
        .mcsSeat {
            position: absolute;
            bottom: 50px;
            left: 200px;
            width: 180px;
            height: 140px;
            overflow: hidden;
            img {
                position: absolute;
                top: -388px;
                right: -5px;
            }
            .msP {
                width: 150px;
                height: 45px;
                color: white;
                position: absolute;
                top: 40px;
                left: 10px;
            }
        }
        .mcsCodes {
            width: 93px;
            height: 93px;
            border: 4px solid #91aab7;
            border-radius: 10px;
            position: absolute;
            bottom: 250px;
            left: 80px;
            .mcsImg {
                width: 100%;
                height: 100%;
                border-radius: 7px;
            }
        }
        .mcsCodes::before {
            position: absolute;
            content: "";
            z-index: -1;
            border: 30px solid transparent;
            border-bottom-width: 15px;
            border-bottom-color: #91aab7;
            right: -20px;
            top: 50%;
            margin-top: -40px;
        }
        .mcsCodeb {
            width: 125px;
            height: 125px;
            border: 4px solid #91aab7;
            position: absolute;
            border-radius: 10px;
            top: 350px;
            right: 20px;
            .mcbImg {
                width: 100%;
                height: 100%;
                border-radius: 7px;
            }
        }
        .mcsCodeb::before {
            position: absolute;
            content: "";
            z-index: -1;
            border: 30px solid transparent;
            border-bottom-width: 15px;
            border-bottom-color: #91aab7;
            left: -30px;
            top: 50%;
            margin-top: -40px;
        }
        .mcsP {
            width: 93px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background-color: #91aab7;
            border-radius: 10px;
            color: white;
            position: absolute;
            bottom: 180px;
            left: 150px;
        }
        .mcsP::before {
            position: absolute;
            content: "";
            z-index: -1;
            border: 30px solid transparent;
            border-bottom-width: 15px;
            border-bottom-color: #91aab7;
            right: -20px;
            top: 50%;
            margin-top: -40px;
        }
    }
    // 漂浮云
    .yun1 {
        position: absolute;
        top: 50px;
        left: 80px;
        // 动画
        transform-origin: center center;
        animation-name: the-yun;
        animation-duration: 60s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        .yunp {
            position: absolute;
            top: 45px;
            left: 65px;
            font-size: 20px;
            font-size: 900;
        }
    }
    .yun2 {
        position: absolute;
        top: 100px;
        right: 80px;
        // 动画
        transform-origin: center center;
        animation-name: the-yun2;
        animation-duration: 60s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        .yunp {
            position: absolute;
            top: 45px;
            left: 65px;
            font-size: 20px;
            font-size: 900;
        }
    }
    .yun3 {
        position: absolute;
        bottom: 70px;
        left: 200px;
        z-index: 6;
        // 动画
        transform-origin: center center;
        animation-name: the-yun3;
        animation-duration: 60s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        .yunp {
            position: absolute;
            top: 45px;
            left: 65px;
            font-size: 20px;
            font-size: 900;
        }
    }
    .di {
        position: absolute;
        bottom: 0;
        left: 100px;
        z-index: 1;
        // 动画
        transform-origin: center center;
        animation-name: the-balloon;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    // 气球动画
    @keyframes the-balloon {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(50px);
        }
        50% {
            transform: translateX(0px);
        }
        75% {
            transform: translateX(50px);
        }
        100% {
            transform: translateX(0);
        }
    }
    // 云1动画
    @keyframes the-yun {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(500px);
        }
        50% {
            transform: translateX(1000px);
        }
        75% {
            transform: translateX(1100px);
            opacity: 0.5;
        }
        100% {
            transform: translateX(0);
        }
    }
    // 云2动画
    @keyframes the-yun2 {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(-500px);
        }
        50% {
            transform: translateX(-1000px);
        }
        75% {
            transform: translateX(-1100px);
            opacity: 0.5;
        }
        100% {
            transform: translateX(0);
        }
    }
    // 云3动画
    @keyframes the-yun3 {
        0% {
            transform: translateX(0);
        }
        25% {
            transform: translateX(500px);
        }
        50% {
            transform: translateX(1000px);
        }
        75% {
            transform: translateX(1080px);
            opacity: 0.5;
        }
        100% {
            transform: translateX(0);
        }
    }
    .yun4 {
        position: absolute;
        top: 180px;
        left: 200px;
        width: 200px;
        animation: the-balloon 30s linear infinite;
        img {
            width: 100%;
        }
    }
    .yun5 {
        position: absolute;
        top: 300px;
        left: 1070px;
        width: 264px;
        animation: the-balloon 30s linear infinite;
        img {
            width: 100%;
        }
    }
    .yun6 {
        position: absolute;
        bottom: -50px;
        right: 300px;
        width: 223px;
        z-index: 3;
        img {
            width: 100%;
        }
    }
    .yun7 {
        position: absolute;
        top: 371px;
        left: 262px;
        width: 118px;
        animation: the-balloon 26s linear infinite;

        img {
            width: 100%;
        }
    }
    .yun8 {
        position: absolute;
        bottom: -16px;
        right: 474px;
        width: 93px;
        z-index: 4;
        img {
            width: 100%;
        }
    }
}
</style>
