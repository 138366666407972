<template>
    <div class="mproCtFour">
        <div class="mcfBg"></div>
        <!-- <img src="http://iv.okvu.cn/vugw/img/bg (1).jpg" class="mcfBg" /> -->
        <div class="mcfBox">
            <!-- 标题 -->
            <div class="mcfTitle">
                <p class="mcfBig wow animate__animated animate__fa4deInUp">什么是全网营销？</p>
                <p class="mcfSmall wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                    全网营销还是一个比较陌生的词，各家网络公司定义不同，威有全网营销是指
                    互联网营销+企业宣传品的整合营销。互联网营销集传统PC网络营销，
                    移动互联网粉丝及关注营销；企业宣传品营销包括商务品牌、宣传页、企业和产品
                    画册、海报、展览会展示等日常发生的线下营销活动。
                </p>
            </div>
            <!-- 圆圈图 -->
            <div class="mcfCircle">
                <div class="clearfix">
                    <div class="mcfP1 wow animate__animated animate__rotateIn" data-wow-delay="0.5s">
                        <p>手机网站二维码</p>
                        <p>企业公众号</p>
                        <p>小程序</p>
                    </div>
                    <div class="mcfP2 wow animate__animated animate__rotateIn" data-wow-delay="0.5s">
                        <p>威有号</p>
                        <p>APP</p>
                    </div>
                </div>
                <!-- 里层文字 -->
                <div class="mcfCtext wow animate__animated animate__rotateIn" data-wow-delay="0.5s">
                    <ul>
                        <li class="mcfCtLi">手机网站二维码</li>
                        <li class="mcfCtLi">企业公众号二维码</li>
                        <li class="mcfCtLi">小程序二维码</li>
                        <li class="mcfCtLi">威有号二维码</li>
                        <li class="mcfCtLi">其他二维码</li>
                    </ul>
                </div>
                <!-- 外层文字 -->
                <div class="mcfCtext2">
                    <ul>
                        <li class="mcfCtLi2 wow animate__animated animate__fadeInLeft" data-wow-delay="0.8s">展会接待台</li>
                        <li class="mcfCtLi2 wow animate__animated animate__fadeInLeft" data-wow-delay="0.8s">促销品</li>
                        <li class="mcfCtLi2 wow animate__animated animate__fadeInLeft" data-wow-delay="0.8s">海报</li>
                        <li class="mcfCtLi2 wow animate__animated animate__fadeInRight" data-wow-delay="0.8s">企业名片</li>
                        <li class="mcfCtLi2 wow animate__animated animate__fadeInRight" data-wow-delay="0.8s">宣传单页</li>
                        <li class="mcfCtLi2 wow animate__animated animate__fadeInRight" data-wow-delay="0.8s">企业画册</li>
                        <li class="mcfCtLi2 wow animate__animated animate__fadeInRight" data-wow-delay="0.8s">产品画册</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MproCtFour",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.mproCtFour {
    // width: 100%;
    height: 800px;
    margin: 0 auto;
    position: relative;
    color: white;
    .mcfBg {
        background-image: url("http://iv.okvu.cn/vugw/img/bg (1).jpg");
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        background-repeat: no-repeat;
        background-position: center top;
        z-index: -1;
    }
    .mcfBox {
        width: 875px;
        // width: 100%;
        // height: 100%;
        margin: 0 auto;
        // overflow: hidden;
        // 标题
        .mcfTitle {
            // width: 70%;
            // height: 135px;
            margin: 0 auto;
            color: white;
            // margin-top: 50px;
            text-align: center;

            .mcfBig {
                // width: 100%;
                font-weight: 900;
                font-size: 36px;
                margin: 0;
                padding: 80px 0 20px 0;
            }
            .mcfSmall {
                // width: 100%;
                // height: 75px;
                // line-height: 25px;
                // text-align: center;
                // margin-top: 10px;
                font-size: 16px;
                line-height: 28px;
                opacity: 0.8;
                margin: 0;
                padding-bottom: 42px;
            }
        }
        // 圆圈图
        .mcfCircle {
            // width: 45%;
            // height: 500px;
            // margin: 0 auto;
            // margin-top: 40px;
            // position: relative;

            width: 495px;
            height: 495px;
            position: relative;
            margin: 0 auto;
            .clearfix {
                position: absolute;
                display: table;
                width: 296px;
                left: 50%;
                margin-left: -145px;
                top: 198px;
                font-size: 18px;
                color: white;
            }
            .mcfP1 {
                display: table-cell;
                vertical-align: middle;
                width: 50%;
                line-height: 26px;
            }
            .mcfP2 {
                display: table-cell;
                vertical-align: middle;
                width: 50%;
                line-height: 26px;
            }
            // 里层文字
            .mcfCtext {
                // width: 576px;
                // height: 500px;
                // color: white;
                .mcfCtLi {
                    // width: 200px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    font-size: 18px;
                }
                .mcfCtLi:nth-child(1) {
                    position: absolute;
                    // top: 8%;
                    // left: 30%;
                    left: 180px;
                    top: 0;
                }
                .mcfCtLi:nth-child(2) {
                    position: absolute;
                    // top: 32%;
                    // right: 0%;
                    right: -29px;
                    top: 142px;
                    transform: rotate(68deg);
                }
                .mcfCtLi:nth-child(3) {
                    position: absolute;
                    // bottom: 18%;
                    // right: 3%;
                    right: 0px;
                    bottom: 127px;
                    transform: rotate(-56deg);
                }
                .mcfCtLi:nth-child(4) {
                    position: absolute;
                    // bottom: 15%;
                    // left: 4%;
                    left: 43px;
                    bottom: 102px;
                    transform: rotate(45deg);
                }
                .mcfCtLi:nth-child(5) {
                    position: absolute;
                    // top: 30%;
                    // left: 0%;
                    left: 17px;
                    top: 108px;
                    transform: rotate(-59deg);
                }
            }
            // 外层文字
            .mcfCtext2 {
                font-weight: 900;
                .mcfCtLi2 {
                    width: 100px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.24);
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: 0.5s;
                }
                .mcfCtLi2:hover {
                    transform: translateY(-10px);
                }
                .mcfCtLi2:nth-child(1) {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
                .mcfCtLi2:nth-child(2) {
                    position: absolute;
                    top: 200px;
                    left: -100px;
                }
                .mcfCtLi2:nth-child(3) {
                    position: absolute;
                    bottom: 40px;
                    left: 0px;
                }
                .mcfCtLi2:nth-child(4) {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                }
                .mcfCtLi2:nth-child(5) {
                    position: absolute;
                    top: 200px;
                    right: -100px;
                }
                .mcfCtLi2:nth-child(6) {
                    position: absolute;
                    bottom: 120px;
                    right: -100px;
                }
                .mcfCtLi2:nth-child(7) {
                    position: absolute;
                    bottom: 20px;
                    right: 10%;
                }
            }
        }
    }
}
</style>
